<template>
  <div>
    <span class="grey--text small-text">{{ $d(moment(date), 'monthYear') }}</span>
    <v-card class="mt-5" color="element" rounded="xl">
      <v-card-text class="defaultText--text">
        <v-expansion-panels
          accordion
          v-model="panel"
          multiple
          elevation-0
        >
          <v-expansion-panel
            v-for="(event, index) in events"
            :key="index"
            :readonly="!event.documents"
          >
            <v-expansion-panel-header color="element" class="px-0" :hide-actions="!event.documents">
              <div class="d-flex align-center">
                <component
                  :color="$vuetify.theme.dark ? '#ffffff' : '#000000'"
                  :is="eventIcons[event.eventType]"
                  class="event-icon"
                />
                <div class="d-flex flex-column ml-3">
                  <span class="grey--text xs-text">{{ $d(moment(event.timestamp), 'document') }}</span>
                  <span class="mt-2 mobile-text legal-name">
                    {{ $t(`eventTypes.${event.eventType}`) }}
                    <span v-if="event.eventType === 'Subscription'">{{ event.eventInfo.legalName }}</span>
                  </span>
                </div>
                <div v-if="event.eventInfo" class="expansion-value">
                  <v-chip
                    v-if="event.eventInfo.amount"
                    :color="event.eventType === 'TransactionDeposit' ? 'success' : 'backgroundDark'"
                    class="mobile-text"
                  >
                    <span>
                      {{ event.eventType === 'TransactionDeposit' ? '+' : '-' }}
                    </span>
                    <span class="ml-1">{{ $n(event.eventInfo.amount, 'floatCurrency') }}</span>
                  </v-chip>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="element" class="pl-2">
              <div
                v-for="(document, index) in event.documents"
                :key="`${index} ${document.documentKey}`"
                class="d-flex mt-3 align-center cursor-pointer content-container"
                @click="downloadDocument(document, event.eventType, event.eventInfo)"
              >
                <div class="d-flex align-center">
                  <File :color="$vuetify.theme.dark ? '#ffffff' : '#000000'" />
                </div>
                <span class="ml-2 mobile-text">{{ $t(documentTitle(document)) }}</span>
                <v-icon class="ml-1">
                  mdi-chevron-right
                </v-icon>
                <div
                  v-if="document.documentTemplateId + document.documentKey === loading"
                  class="disabled-download element"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import Calendar from '@/assets/icons/svg/Calendar.vue';
import File from '@/assets/icons/svg/File.vue';
import Wallet from '@/assets/icons/svg/Wallet.vue';
import Profile from '@/assets/icons/svg/Profile.vue';
import Bank from '@/assets/icons/svg/Bank.vue';
import { getSignedDownloadUrl } from '@/api/documents';

export default {
  name: 'SignedDocuments',
  components: {
    Calendar,
    File,
    Wallet,
    Profile,
    Bank,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      panel: [],
      eventIcons: {
        Subscription: 'Wallet',
        Onboarding: 'Calendar',
        ProkClassification: 'Profile',
        ProkReclassification: 'Profile',
        ProkDowngrade: 'Profile',
        TransactionDeposit: 'Bank',
        TransactionWithdrawal: 'Bank',
      },
      loading: null,
    };
  },
  methods: {
    moment(date) {
      return moment(date);
    },
    documentTitle(document) {
      const translationKey = document.documentTemplateId.replace(/_/g, '.');
      const title = `${translationKey}.${document.documentKey}.title`;
      return title;
    },
    async downloadDocument(documentInfo, eventType, eventInfo) {
      const { documentTemplateId, documentKey } = documentInfo;
      this.loading = documentTemplateId + documentKey;
      const input = {
        documentTemplateId,
        documentKey,
      };
      let titleSuffix = '';
      if (eventType === 'ProkClassification' || eventType === 'ProkReclassification') {
        input.params = {
          useCase: 'Prok',
          prokId: eventInfo.prokId,
        };
        titleSuffix += ` - ${eventInfo.prokId}`;
      }
      if (eventType === 'Subscription') {
        input.params = {
          useCase: 'Subscription',
          orderId: eventInfo.orderId,
          assetId: eventInfo.assetId,
        };
        titleSuffix += ` - ${eventInfo.legalName}`;
      }
      const response = await getSignedDownloadUrl(input);
      const link = document.createElement('a');
      link.download = `${this.$t(this.documentTitle(documentInfo))}${titleSuffix}.pdf`;
      link.href = response.pdfInBase64;
      link.click();
      this.loading = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.small-text{
  font-size: 14px;
}
.xs-text{
  font-size: 12px;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panels--accordion>.v-expansion-panel:after {
  opacity: 0;
}
.event-icon {
  width: 25px;
  height: 25px;
}
.content-container {
  position: relative;
}
.disabled-download {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.expansion-value {
  position: absolute;
  right: 30px;
}

@media (max-width: 600px) {
  .mobile-text {
    font-size: 10px;
  }
  .xs-text{
    font-size: 8px;
  }
  .legal-name {
    max-width: 165px;
  }
}
</style>
