<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.09375 9.9025H20.9177" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.4429 13.8088H16.4522" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0054 13.8088H12.0147" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.56013 13.8088H7.56939" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.4429 17.6994H16.4522" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0054 17.6994H12.0147" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.56013 17.6994H7.56939" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.0413 2.5V5.79078" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.9632 2.5V5.79078" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M16.2383 4.07812H7.77096
      C4.83427 4.07812 3 5.71406 3 8.72115V17.7708
      C3 20.8252 4.83427 22.4989 7.77096 22.4989H16.229
      C19.175 22.4989 21 20.8535 21 17.8464V8.72115
      C21.0092 5.71406 19.1842 4.07812 16.2383 4.07812Z"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Calendar',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
